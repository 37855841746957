import ApplicationLogo from '@/Components/ApplicationLogo';
import Testimonial from '@/Components/Testimonial';
import { PropsWithChildren, useEffect } from 'react';
import LanguageSwitcher from '@/Components/LanguageSwitchter';
import { useTranslation } from 'react-i18next';
import { usePage } from '@inertiajs/react';
import '@/i18n/config';
import i18n from 'i18next';
import { PageProps } from '@/types';

export default function Guest({ children }: PropsWithChildren) {
    const { t } = useTranslation();
    const { locale } = usePage<PageProps>().props;

    useEffect(() => {
        i18n.changeLanguage(locale.current);
        localStorage.setItem('preferredLanguage', locale.current);
    }, [locale.current]);

    const testimonial = {
        feedback: t('auth.testimonials.feedback'),
        user: {
            name: 'Jelena Puzic',
            role: t('auth.testimonials.user.role'),
            image: {
                path: '/images/avatar/jelena-puzic.png',
                alt: 'Jelena Puzic',
            },
        },
    };

    return (
        <div className="flex min-h-screen bg-mw-bg">
            <div className="flex flex-col flex-1">
                <div className="mx-auto w-full max-w-sm lg:w-96 pt-8">
                    <div className="flex items-center justify-between">
                        <ApplicationLogo className="h-24" />
                        <div className="ml-4">
                            <LanguageSwitcher />
                        </div>
                    </div>
                </div>

                <div className="mx-auto w-full max-w-sm lg:w-96">
                    <div className="mt-10">{children}</div>
                </div>
            </div>

            <div className="relative hidden lg:flex lg:w-1/3 bg-gradient-to-r from-mw-orange to-mw-magenta">
                <div className="w-full">
                    <Testimonial
                        className="fixed bottom-12 xl:bottom-16 mx-8"
                        feedback={testimonial.feedback}
                        user={testimonial.user}
                    />
                </div>
            </div>
        </div>
    );
}
