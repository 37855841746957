import React, { useState } from 'react';
import { Globe } from 'lucide-react';
import { router, usePage } from '@inertiajs/react';
import { PageProps } from '@/types';

const LANGUAGES = {
    en: 'English',
    de: 'Deutsch',
} as const;

type LanguageCode = keyof typeof LANGUAGES;
type Language = (typeof LANGUAGES)[LanguageCode];

interface LocalePageProps extends PageProps {
    locale: {
        current: LanguageCode;
        available: LanguageCode[];
    };
}

export default function LanguageSwitcher() {
    const [isOpen, setIsOpen] = useState(false);
    const { locale } = usePage<LocalePageProps>().props;

    const switchLanguage = (code: LanguageCode) => {
        setIsOpen(false);

        router.post(
            route('language.switch'),
            {
                language: code,
            },
            {
                preserveState: true,
                preserveScroll: true,
            }
        );
    };

    return (
        <div className="relative">
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center gap-2 rounded-full bg-white px-4 py-2 text-sm text-mw-text shadow-sm hover:bg-gray-50 transition-colors"
            >
                <Globe className="h-4 w-4" />
                <span>{LANGUAGES[locale.current]}</span>
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-1 w-32 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                    <div className="py-1" role="menu">
                        {locale.available.map((code) => (
                            <button
                                key={code}
                                onClick={() => switchLanguage(code)}
                                className={`block w-full px-4 py-2 text-sm text-left
                                    ${
                                        locale.current === code
                                            ? 'bg-gray-50 text-mw-text font-medium'
                                            : 'text-mw-text hover:bg-gray-50'
                                    }`}
                                role="menuitem"
                            >
                                {LANGUAGES[code]}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}
